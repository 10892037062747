import { useEffect, useRef, type PropsWithChildren } from 'react';
import { animations } from '@formkit/drag-and-drop';
import { useDragAndDrop } from '@formkit/drag-and-drop/react';
import classNames from 'classnames';

import { useAppDispatch, useAppSelector } from '@/store/hooks.ts';
import {
  colorsRulesSlice,
  deleteRuleThunk,
  replaceRulesThunk,
  rollbackRuleThunk,
  setRulesThunk,
  stopEditAndSaveRuleThunk,
  toggleRuleThunk,
  validateColorRules,
  type ColorRule,
} from '@/store/slices/colorRules/colorsRulesSlice.ts';
import { addErrorToastThunk } from '@/store/slices/ui/uiSlice.ts';
import { Button } from '@/components/common/bootstrap/Button.tsx';
import { Dropdown } from '@/components/common/bootstrap/Dropdown.tsx';
import { Switcher } from '@/components/common/bootstrap/Switcher.tsx';
import { ColorRuleEdit } from '@/components/prefs/tabs/ColorRules/ColorRuleEdit.tsx';
import { downloadBlob } from '@/utils/downloadBlob.ts';
import { parseJson, stringifyJson } from '@/utils/json.ts';

export function ColorCodingTab() {
  const colorRules = useAppSelector(state => state.colorRules.rules);
  const editionState = useAppSelector(state => state.colorRules.editionState);
  const dispatch = useAppDispatch();

  const [colorRulesRef, currentColorRules, setCurrentColorRules] = useDragAndDrop<
    HTMLUListElement,
    ColorRule
  >(colorRules, {
    group: 'currentColorRules',
    dragHandle: '.colorcoding-draghandle',
    plugins: [animations()],
    onDragend: event => dispatch(setRulesThunk(event.values as ColorRule[])),
    draggingClass: 'dnd-grabzone',
  });

  useEffect(() => {
    setCurrentColorRules(colorRules);
  }, [colorRules, setCurrentColorRules]);

  function onEdit(index: number) {
    dispatch(colorsRulesSlice.actions.editRule({ index }));
  }

  function onDisable(index: number, enabled: boolean) {
    dispatch(toggleRuleThunk(index, enabled));
  }

  function onDelete(index: number) {
    dispatch(deleteRuleThunk(index));
  }

  function onCreate() {
    dispatch(colorsRulesSlice.actions.addRule());
  }

  if (editionState !== undefined) {
    const onCancel = function () {
      dispatch(rollbackRuleThunk());
    };

    function onClose() {
      dispatch(stopEditAndSaveRuleThunk());
    }

    return <ColorRuleEdit editIndex={editionState.index} onClose={onClose} onCancel={onCancel} />;
  }

  async function importFiles(file: File) {
    const rules = validateColorRules(parseJson(await file.text()));
    if (rules.length === 0) {
      dispatch(addErrorToastThunk('Invalid rules file'));
    } else {
      dispatch(replaceRulesThunk(rules));
    }
  }

  function onExport() {
    downloadJson('rules.json', colorRules);
  }

  return (
    <>
      <div className="btn-group">
        <UploadButton onUpload={importFiles} />
        <Button className="btn-icon-start my-3" onClick={onExport}>
          <em className="icon">download</em>
          Export
        </Button>
        <Button className="btn-icon-start my-3" onClick={onCreate}>
          <em className="icon">add</em>
          Create
        </Button>
      </div>
      <ul ref={colorRulesRef} className="d-flex flex-column list-unstyled">
        {currentColorRules.map((rule, index) => (
          <li key={rule.name} data-e2e="rule" className=" cursor-grab">
            <RuleCard
              key={rule.name}
              index={index}
              rule={rule}
              onToggle={checked => onDisable(index, checked)}
              onEdit={() => onEdit(index)}
              onDelete={() => onDelete(index)}
            >
              <span className={`${rule.bgColor} ${rule.textColor}`}>{rule.name}</span>
            </RuleCard>
          </li>
        ))}
      </ul>
    </>
  );
}

function RuleCard({
  index,
  rule,
  children,
  onToggle,
  onEdit,
  onDelete,
}: PropsWithChildren<{
  index: number;
  rule: ColorRule;
  onToggle: (checked: boolean) => void;
  onEdit: () => void;
  onDelete: () => void;
}>) {
  function handleDropdownClick(action: 'edit' | 'delete') {
    if (action === 'delete') {
      onDelete();
    } else {
      onEdit();
    }
  }

  return (
    <div className="card card-raising card-bordered m-1 bg-lvl2">
      <div className="d-flex card-body p-2 justify-content-between gap-2 align-items-center cursor-grab colorcoding-draghandle">
        <div className="d-flex align-items-center">
          <i
            className={classNames('icon icon-md cursor-grab', {
              'text-dark': !rule.enabled,
            })}
            style={{ minWidth: 20 }}
          >
            drag_indicator
          </i>
          <span
            className={classNames('badge rounded-pill', {
              'bg-primary': rule.enabled,
              'bg-dark': !rule.enabled,
            })}
          >
            {index + 1}
          </span>
        </div>

        <div>{children}</div>

        <div className="d-flex align-items-center gap-2">
          <Dropdown
            itemsAsObjects={['edit', 'delete']}
            ItemRenderer={ItemRenderer}
            onItemClick={handleDropdownClick}
          >
            <span
              className={classNames({
                'text-dark': !rule.enabled,
              })}
            >
              Actions
            </span>
          </Dropdown>
          <Switcher checked={rule.enabled} onChange={onToggle} />
        </div>
      </div>
    </div>
  );
}

function ItemRenderer({ item }: { item: 'edit' | 'delete' }) {
  switch (item) {
    case 'delete':
      return <span className="text-danger">Delete</span>;
    case 'edit':
      return <span>Edit</span>;
  }
}

function downloadJson(filename: string, colorRules: ColorRule[]) {
  const blob = new Blob([stringifyJson(colorRules)], {
    type: 'application/json',
  });
  downloadBlob(filename, blob);
}

function UploadButton({ onUpload }: { onUpload: (file: File) => void }) {
  const uploadInputRef = useRef<HTMLInputElement>(null);
  function onButtonClick() {
    uploadInputRef.current?.click();
  }
  function onInputChange(files: FileList | null) {
    if (files !== null && files.length > 0) {
      onUpload(files[0]);
    }
  }
  return (
    <>
      <input
        ref={uploadInputRef}
        onChange={event => onInputChange(event.target.files)}
        type="file"
        accept="application/json"
        className="d-none"
      />
      <Button className="btn-icon-start my-3" onClick={onButtonClick}>
        <em className="icon">upload</em>
        Import
      </Button>
    </>
  );
}
